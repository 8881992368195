import { Outlet } from 'react-router-dom'
import { Icon } from '@iconify/react'
import menu2Fill from '@iconify/icons-eva/menu-2-fill'
import { Container, Stack, Fab } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'

import { IconButton, LanguageToggle, MHidden, Sidebar } from '../components'
import { useAuth, useDrawer, useDrawerActions } from '../controllers'

const RootStyle = styled('div')({
  display: 'flex',
  backgroundColor: 'var(--background)'
})

const OutletStyle = styled('div')({
  paddingTop: '1rem',
  paddingBottom: '6rem',
  overflow: 'visible'
})

function Dashboard() {
  const { isOpen, sidebarConfig, isLoading } = useDrawer()
  const { logout } = useAuth()
  const { toggleDrawer } = useDrawerActions()
  const dismissible = true

  return (
    <RootStyle>
      <Sidebar
        isOpenSidebar={isOpen}
        onOpenSidebar={toggleDrawer}
        onCloseSidebar={toggleDrawer}
        dismissible={dismissible}
        sidebarConfig={sidebarConfig}
      />
      <Container>
        <Stack
          m="1.5rem"
          direction="row-reverse"
          alignItems="center"
          justifyContent="space-between"
        >
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <LanguageToggle />
            <button
              type="button"
              htmlFor="button"
              style={{ marginLeft: 20, borderWidth: 0, backgroundColor: 'transparent' }}
              onClick={() => logout()}
              onKeyDown={() => logout()}
            >
              <Fab component="span" sx={{ color: 'var(--menu_accent)', minWidth: 56 }}>
                <ExitToAppIcon />
              </Fab>
            </button>
          </div>
          <MHidden width="lgUp">
            <IconButton onClick={toggleDrawer}>
              <Icon icon={menu2Fill} />
            </IconButton>
          </MHidden>
        </Stack>
        <OutletStyle>
          <Outlet />
        </OutletStyle>
      </Container>
    </RootStyle>
  )
}

export default Dashboard
