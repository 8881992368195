import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import { styled } from '@material-ui/core/styles'
import { Button, Link, Typography, CardContent } from '@material-ui/core'

import { fDate } from '../../../utils/formatTime'

const TitleStyle = styled(Link)({
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  alignContent: 'flex-end'
})

const CoverImgStyle = styled('img')({
  width: '100%',
  objectFit: 'cover'
})

function BlogPostCard({ post, index }) {
  const { cover, title, createdAt } = post

  return (
    <Button
      component={RouterLink}
      to={`post/${index}`}
      item
      xs={12}
      sm={6}
      md={3}
      sx={{
        display: 'flex',
        margin: '2rem 0',
        position: 'relative',
        p: 0,
        overflow: 'hidden',
        color: 'var(--menu_accent)',
        backgroundColor: 'var(--card)',
        boxShadow: `
        0px 8px 10px -5px var(--shadow_020),
        0px 16px 24px 2px var(--shadow_014),
        0px 6px 30px 5px var(--shadow_012)
      `
      }}
    >
      <CoverImgStyle alt={title} src={cover} />
      <Typography
        gutterBottom
        variant="caption"
        sx={{
          position: 'absolute',
          top: '1rem',
          right: '1rem',
          alignSelf: 'flex-start',
          padding: '0.3rem 1rem',
          borderRadius: '1rem',
          color: 'var(--menu_text)',
          backgroundColor: 'var(--menu_accent)'
        }}
      >
        {fDate(createdAt)}
      </Typography>
      <CardContent
        sx={{
          p: '1rem',
          pt: '3rem',
          display: 'flex',
          flexDirection: 'column',
          background: 'linear-gradient(to top, var(--card), transparent)',
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          '&:last-child': {
            pb: '1rem'
          }
        }}
      >
        <TitleStyle
          color="inherit"
          variant="subtitle2"
          underline="none"
          sx={{
            color: 'var(--foreground)',
            flexGrow: 1,
            alignItems: 'center'
          }}
        >
          {title}
        </TitleStyle>
      </CardContent>
    </Button>
  )
}

BlogPostCard.propTypes = {
  post: PropTypes.object.isRequired,
  index: PropTypes.number
}

export default BlogPostCard
