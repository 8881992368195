import { useParams } from 'react-router-dom'
import { Grid, Container } from '@material-ui/core'

import Page from '../components/Page'
import { BlogPostCard } from '../components/_dashboard/blog'
import POSTS from '../_mocks_/blog'

function Blog() {
  const { id } = useParams()

  return (
    <Page title={id}>
      <Container sx={{ marginY: '2rem' }}>
        <Grid container spacing="1.5rem">
          {POSTS.map((post, index) => (
            <BlogPostCard
              key={post.id}
              post={post}
              index={index}
              redirectTo={`post/${index}`}
              shouldReplace={false}
            />
          ))}
        </Grid>
      </Container>
    </Page>
  )
}

export default Blog
