function copyToClipboard(text, onSuccess = () => {}, onError = () => {}) {
  if (navigator.clipboard?.writeText) {
    navigator.clipboard.writeText(text).then(onSuccess, onError)
  } else {
    const textArea = document.createElement('textArea')
    textArea.style = `
      position: fixed;
      top: 0;
      left: 0;
      width: 2rem;
      height: 2em;
      padding: 0;
      border: none;
      outline: none;
      box-shadow: none;
      background: transparent;
    `
    textArea.value = text

    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try {
      document.execCommand('copy')
      onSuccess()
    } catch (e) {
      onError(e)
    }

    document.body.removeChild(textArea)
  }
}

export default copyToClipboard
