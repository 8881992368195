import React, { useState } from 'react'
import moment from 'moment'
import { Container } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'

import { EventItem, Page } from '../components'
import { useLanguage } from '../controllers'

const EventsContainer = styled('div')({
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'center',
  backgroundColor: 'var(--transparent)'
})

const EventsContent = styled(Container)({
  display: 'flex',
  flexDirection: 'column'
})

function Events() {
  useLanguage()

  const [data] = useState([
    {
      id: '000-0000-0000-000',
      type: 'section',
      startTime: moment().startOf('month').format('YYYY-MM-DD hh:mm')
    },
    {
      id: '0000-000-000-0000',
      type: 'event',
      date: moment().add(2, 'days').format('YYYY-MM-DD hh:mm'),
      startTime: moment().add(1, 'hour').format('YYYY-MM-DD hh:mm'),
      endTime: moment().add(2, 'days').add(1, 'hour').format('YYYY-MM-DD hh:mm'),
      title: 'Perballje fizike mes banoreve te Laknasit dhe Bashkise Kamez!!',
      description:
        'Tani ne zonen e Plepave te Brukes po behet rezistence nga ana e banoreve per te mbrojtur zonen pyjore te Laknasit nga privatizimi dhe kthimi ne thertore nga nje privat!',
      location: 'Tirane',
      imageUrl: 'https://source.unsplash.com/1600x900/?group'
    },
    {
      id: '1111-111-111-1111',
      type: 'event',
      date: moment().add(2, 'days').format('YYYY-MM-DD hh:mm'),
      startTime: moment().add(2, 'days').format('YYYY-MM-DD hh:mm'),
      endTime: moment().add(2, 'days').add(1, 'hour').format('YYYY-MM-DD hh:mm'),
      title: 'Perballje fizike mes banoreve te Laknasit dhe Bashkise Kamez!!',
      description:
        'Tani ne zonen e Plepave te Brukes po behet rezistence nga ana e banoreve per te mbrojtur zonen pyjore te Laknasit nga privatizimi dhe kthimi ne thertore nga nje privat!',
      location: 'Kamez',
      imageUrl: 'https://source.unsplash.com/1600x900/?theater'
    },
    {
      id: '111-1111-1111-111',
      type: 'section',
      startTime: moment().add(1, 'month').startOf('month').format('YYYY-MM-DD hh:mm')
    },
    {
      id: '2222-222-222-2222',
      type: 'event',
      date: moment().add(2, 'days').format('YYYY-MM-DD hh:mm'),
      startTime: moment().add(1, 'month').add(1, 'day').format('YYYY-MM-DD hh:mm'),
      endTime: moment().add(1, 'month').add(2, 'days').add(1, 'hour').format('YYYY-MM-DD hh:mm'),
      title: 'Perballje fizike mes banoreve te Laknasit dhe Bashkise Kamez!!',
      description:
        'Tani ne zonen e Plepave te Brukes po behet rezistence nga ana e banoreve per te mbrojtur zonen pyjore te Laknasit nga privatizimi dhe kthimi ne thertore nga nje privat!',
      location: 'Laknas',
      imageUrl: 'https://source.unsplash.com/1600x900/?protest'
    },
    {
      id: '3333-333-333-3333',
      type: 'event',
      date: moment().add(2, 'days').format('YYYY-MM-DD hh:mm'),
      startTime: moment().add(1, 'month').add(6, 'days').format('YYYY-MM-DD hh:mm'),
      endTime: moment().add(1, 'month').add(7, 'days').add(1, 'hour').format('YYYY-MM-DD hh:mm'),
      title: 'Perballje fizike mes banoreve te Laknasit dhe Bashkise Kamez!!',
      description:
        'Tani ne zonen e Plepave te Brukes po behet rezistence nga ana e banoreve per te mbrojtur zonen pyjore te Laknasit nga privatizimi dhe kthimi ne thertore nga nje privat!',
      location: 'Kamez',
      imageUrl: 'https://source.unsplash.com/1600x900/?cinema'
    }
  ])

  const renderItem = ({ id, ...item }) => <EventItem key={id} {...item} />

  return (
    <Page title="Events">
      <EventsContainer>
        <EventsContent>{data.map(renderItem)}</EventsContent>
      </EventsContainer>
    </Page>
  )
}

export default Events
