import React, { createContext, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'react-apollo'
import { generatePath } from 'react-router-dom'

import { LIST_CATEGORIES } from '../api/Categories.Schema'
import { Loader } from '../components'

const DrawerState = createContext()
const DrawerActions = createContext()
const initialState = { isLoading: true, isOpen: false, sidebarConfig: [] }

function DrawerProvider({ children }) {
  const [state, setState] = useState(initialState)

  const toggleDrawer = () => {
    setState((current) => ({
      ...current,
      isOpen: !current.isOpen
    }))
  }

  const updateConfig = (config) => {
    setState((current) => ({
      ...current,
      sidebarConfig: config,
      isLoading: false
    }))
  }

  const { data } = useQuery(LIST_CATEGORIES, {
    variables: {
      listCategoriesInput: {}
    },
    fetchPolicy: 'network-only',
    onCompleted: () => {
      const finalData = []
      const categoriesData = data?.listCategories?.categories
      if (Array.isArray(categoriesData)) {
        generatePath('/admin/blog/:id', { id: categoriesData?.[0]?.id })

        categoriesData?.map((item) => {
          let blog = true
          let isKujri = false
          if (item?.url?.includes('about') ||
            item?.url?.includes('calendar') ||
            item?.url?.startsWith('http')) {
            blog = false
          } else if (item?.nameSq?.toLowerCase() === 'kujri') {
            isKujri = true
          }
          finalData.push({
            id: item.id,
            title: {
              sq: item?.nameSq,
              en: item?.nameEn
            },
            path: `/admin/blog/${item?.id}?blog=${blog}&kujri=${isKujri}`,
            orderNr: item?.orderNr,
            blog,
            url: item?.url,
          })
          return finalData
        })
      }
      updateConfig(finalData.sort((a, b) => a?.orderNr - b?.orderNr))
    }
  })

  const actions = useMemo(
    () => ({
      toggleDrawer,
      updateConfig
    }),
    []
  )

  return (
    <DrawerState.Provider value={state}>
      <DrawerActions.Provider value={actions}>
        {state.isLoading ? <Loader /> : children}
      </DrawerActions.Provider>
    </DrawerState.Provider>
  )
}
DrawerProvider.propTypes = {
  children: PropTypes.any
}

function useDrawer() {
  const context = useContext(DrawerState)
  if (context === undefined) {
    throw new Error('useDrawer must be used within a DrawerProvider')
  }
  return context
}

function useDrawerActions() {
  const context = useContext(DrawerActions)
  if (context === undefined) {
    throw new Error('useDrawerActions must be used within a DrawerProvider')
  }
  return context
}

export { DrawerProvider, useDrawer, useDrawerActions }
export default DrawerProvider
