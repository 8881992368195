import PropTypes from 'prop-types'
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'

import { Loader } from '../components'
import I18n, { setLanguage } from '../translations'

const LanguageState = createContext()
const LanguageActions = createContext()
const initialState = { isLoading: true, language: 'sq' }
const noop = () => {}
const initialActions = {
  changeLanguage: noop
}

function LanguageProvider({ children }) {
  const [state, setState] = useState(initialState)
  const [actions, setActions] = useState(initialActions)

  const changeLanguage = useCallback((language) => {
    setLanguage(language)
    setState((current) => ({
      ...current,
      language,
      isLoading: false
    }))
  }, [])

  useEffect(() => {
    changeLanguage(I18n.currentLocale())
  }, [changeLanguage])

  useEffect(() => {
    setActions((current) => ({
      ...current,
      changeLanguage
    }))
  }, [changeLanguage])

  return (
    <LanguageState.Provider value={state}>
      <LanguageActions.Provider value={actions}>
        {state.isLoading ? <Loader /> : children}
      </LanguageActions.Provider>
    </LanguageState.Provider>
  )
}
LanguageProvider.propTypes = {
  children: PropTypes.any
}

function useLanguage() {
  const context = useContext(LanguageState)
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider')
  }
  return context
}

function useLanguageActions() {
  const context = useContext(LanguageActions)
  if (context === undefined) {
    throw new Error('useLanguageActions must be used within a LanguageProvider')
  }
  return context
}

export { LanguageProvider, useLanguage, useLanguageActions }
