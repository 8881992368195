import { IconButton as MUIIconButton } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'

const IconButton = styled(MUIIconButton)({
  position: 'relative',
  width: '4rem',
  height: '4rem',
  fontsize: '4rem',
  color: 'var(--foreground)',
  backgroundColor: 'var(--background)',
  border: '0.0625rem solid var(--border_010)',
  zIndex: 10,
  boxShadow: `
    0px 8px 10px -5px var(--shadow_020),
    0px 16px 24px 2px var(--shadow_014),
    0px 6px 30px 5px var(--shadow_012)
  `,
  transition: '.2s',
  '&:hover': {
    color: 'var(--background)',
    backgroundColor: 'var(--primary)',
    borderColor: 'var(--primary)'
  }
})

export default IconButton
