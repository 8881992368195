import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Icon } from '@iconify/react'
import chevronUpOutline from '@iconify/icons-eva/chevron-up-outline'

import IconButton from './IconButton'

export default function ScrollToTop() {
  const { pathname } = useLocation()

  const [isVisible, setVisible] = useState(false)

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop
    if (scrolled > 300) {
      setVisible(true)
    } else if (scrolled <= 300) {
      setVisible(false)
    }
  }

  const scrollToTop = ({ behavior = 'smooth' }) => {
    document.documentElement.scrollTo({
      top: 0,
      behavior
    })
  }

  useEffect(() => {
    scrollToTop('auto')
  }, [pathname])

  useEffect(() => {
    window.addEventListener('scroll', toggleVisible, { passive: true })
    return () => window.removeEventListener('scroll', toggleVisible, { passive: true })
  }, [])

  if (isVisible) {
    return (
      <IconButton
        onClick={scrollToTop}
        sx={{ position: 'fixed', bottom: '1.5rem', right: '1.5rem', top: 'unset', left: 'unset' }}
      >
        <Icon icon={chevronUpOutline} fontSize="2rem" />
      </IconButton>
    )
  }

  return null
}
