import gql from 'graphql-tag'

export const CREATE_POST = gql`
  mutation createPost(
    $titleEn: String!
    $titleSq: String!
    $contentEn: String!
    $contentSq: String!
    $groupId: String
    $categoryId: String
    $url: String!
    $key: String!
    $galleryUrls: [ImageUrlInput]
  ) {
    createPost(
      input: {
        titleEn: $titleEn
        titleSq: $titleSq
        contentEn: $contentEn
        contentSq: $contentSq
        groupId: $groupId
        categoryId: $categoryId
        imageUrl: { url: $url, key: $key }
        galleryUrls: $galleryUrls
      }
    ) {
      id
      imageUrl {
        key
        url
      }
    }
  }
`

export const UPDATE_POST = gql`
  mutation putPost(
    $id: String!
    $titleEn: String!
    $titleSq: String!
    $contentEn: String!
    $contentSq: String!
    $groupId: String
    $categoryId: String
    $url: String!
    $key: String!
    $galleryUrls: [ImageUrlInput]
  ) {
    putPost(
      input: {
        id: $id
        titleEn: $titleEn
        titleSq: $titleSq
        contentEn: $contentEn
        contentSq: $contentSq
        groupId: $groupId
        categoryId: $categoryId
        imageUrl: { url: $url, key: $key }
        galleryUrls: $galleryUrls
      }
    ) {
      id
      imageUrl {
        key
        url
      }
    }
  }
`

export const DELETE_POST = gql`
  mutation deletePost($id: String!) {
    deletePost(id: $id) {
      id
      imageUrl {
        url
        key
      }
    }
  }
`

export const LIST_POSTS = gql`
  query listPosts($listPostsInput: ListInput) {
    listPosts(listPostsInput: $listPostsInput) {
      nextToken
      posts {
        id
        titleEn
        titleSq
        createdAt
        imageUrl {
          url
          key
        }
        contentEn
        contentSq
      }
    }
  }
`

export const GET_POST = gql`
  query getPost($fields: String, $id: String!) {
    getPost(fields: $fields, id: $id) {
      id
      titleEn
      titleSq
      contentEn
      contentSq
      imageUrl {
        url
        key
      }
      galleryUrls {
        url
        key
      }
    }
  }
`
