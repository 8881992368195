import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { Helmet } from 'react-helmet'
import { Box } from '@material-ui/core'

const Page = forwardRef(({ children, title = '', description = '', ...other }, ref) => (
  <Box ref={ref} {...other}>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <link rel="canonical" href="https://source.unsplash.com/random" />
      <meta name="description" content={description} />
    </Helmet>
    {children}
  </Box>
))

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  description: PropTypes.string
}

export default Page
