function Button() {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            boxShadow: 'none'
          }
        },
        sizeLarge: {
          height: 48
        },
        containedInherit: {
          color: 'var(--foreground)',
          boxShadow: 'var(--shadow)',
          '&:hover': {
            backgroundColor: 'var(--grey)'
          }
        },
        containedPrimary: {
          boxShadow: 'var(--shadow)'
        },
        containedSecondary: {
          boxShadow: 'var(--shadow)'
        },
        outlinedInherit: {
          border: `1px solid var(--grey_032)`,
          '&:hover': {
            backgroundColor: 'var(--grey_032)'
          }
        },
        textInherit: {
          '&:hover': {
            backgroundColor: 'var(--grey_032)'
          }
        }
      }
    }
  }
}

export default Button
