/* eslint-disable react-hooks/exhaustive-deps */
import * as Yup from 'yup'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { useFormik, Form, FormikProvider } from 'formik'
import { Icon } from '@iconify/react'
import eyeFill from '@iconify/icons-eva/eye-fill'
import eyeOffFill from '@iconify/icons-eva/eye-off-fill'
// material
import { Stack, TextField, IconButton, InputAdornment } from '@material-ui/core'
import { LoadingButton } from '@material-ui/lab'
import { useAuth } from '../../../controllers'

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate()
  const { isAuthenticated, getAuthenticatedUser } = useAuth()
  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false)

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required')
  })

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: () => {}
  })

  const { values, getFieldProps } = formik

  const [error, setError] = useState()

  const handleShowPassword = () => {
    setShowPassword((show) => !show)
  }

  const handleLogin = () => {
    setLoading(true)
    Auth.signIn(values.email, values.password)
      .then(() => {
        getAuthenticatedUser()
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        setError(err)
      })
  }

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/admin/blog', { replace: true })
    }
  }, [isAuthenticated])

  return (
    <FormikProvider value={formik}>
      <Form>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="username"
            label="Username"
            {...getFieldProps('email')}
            error={Boolean(error?.message)}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(error?.message)}
            helperText={error?.message}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          {/* <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />

          <Link component={RouterLink} variant="subtitle2" to="#">
            Forgot password?
          </Link> */}
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          // type="submit"
          variant="contained"
          loading={loading}
          onClick={handleLogin}
        >
          Login
        </LoadingButton>
      </Form>
    </FormikProvider>
  )
}
