// import { Home } from './Routes';

// function App() {
//   return (
//     <div className="App">
//       <div className="menu"></div>
//       <Home/>
//     </div>
//   );
// }

// export default App;

// routes
import Amplify, { Auth } from 'aws-amplify'
import { ApolloProvider } from 'react-apollo'

import { createAuthLink } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'

import { ApolloLink } from 'apollo-link'
import { createHttpLink } from 'apollo-link-http'
import ApolloClient from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'

import Router from './routes'
import { amplifyConfig } from './config'
// theme
import ThemeConfig from './theme'
// components
import ScrollToTop from './components/ScrollToTop'
import { DrawerProvider, AuthProvider, useAuth } from './controllers'
import { Loader } from './components'
import { Login } from './pages'

Amplify.configure(amplifyConfig)

const url = process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT
const region = process.env.REACT_APP_AWS_APPSYNC_REGION
const auth = {
  type: process.env.REACT_APP_AWS_APPSYNC_AUTHENTICATION_TYPE,
  jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken()
}

const httpLink = createHttpLink({ uri: url })

const link = ApolloLink.from([
  createAuthLink({ url, region, auth }),
  createSubscriptionHandshakeLink({ url, region, auth }, httpLink)
])

const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    addTypename: false
  }),
})

const Pages = () => {
  const { isAuthenticated, isLoading } = useAuth()

  if (isLoading) {
    return <Loader />
  }

  if (isAuthenticated) {
    return (
      <DrawerProvider>
        <ScrollToTop />
        <Router />
      </DrawerProvider>
    )
  }

  return <Login />
}

export default function App() {
  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <ThemeConfig>
          <Pages />
        </ThemeConfig>
      </AuthProvider>
    </ApolloProvider>
  )
}
