import PropTypes from 'prop-types'
import { styled } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { useLanguage, useLanguageActions } from '../controllers'

const Container = styled('div')({
  display: 'flex',
  position: 'relative',
  borderRadius: '1rem',
  fontsize: '4rem',
  color: 'var(--menu_accent)',
  backgroundColor: 'var(--card)',
  border: '0.0625rem solid var(--border_010)',
  zIndex: 10
})

const Selected = styled('div')({
  display: 'flex',
  position: 'absolute',
  top: '0.3rem',
  bottom: '0.3rem',
  left: '0.3rem',
  width: '4rem',
  zIndex: -1,
  backgroundColor: 'var(--menu_accent)',
  transition: '.3s',
  boxShadow: '0px 0px 8px 0px var(--shadow_020)'
})

const ItemStyle = styled(Button)({
  display: 'flex',
  padding: '0.7rem 0.7rem',
  margin: '0.3rem',
  width: '3.4rem',
  borderRadius: '0.7rem 0.3rem 0.3rem 0.7rem',
  justifyContent: 'center',
  color: 'var(--menu_accent)'
})

function Item({ name, onClick, selected, sx, value, ...props }) {
  const isSelected = value === selected
  const handleClick = () => {
    onClick(value)
  }

  return (
    <ItemStyle
      sx={{
        ...sx,
        ...(isSelected && {
          color: 'var(--menu_text)',
          fontWeight: 'bold'
        })
      }}
      {...props}
      onClick={handleClick}
    >
      {name}
    </ItemStyle>
  )
}

Item.defaultProps = {
  sx: {}
}

Item.propTypes = {
  name: PropTypes.oneOf(['AL', 'EN']),
  onClick: PropTypes.func,
  selected: PropTypes.oneOf(['sq', 'en']),
  sx: PropTypes.object,
  value: PropTypes.oneOf(['sq', 'en'])
}

function LanguageToggle() {
  const { language } = useLanguage()
  const { changeLanguage } = useLanguageActions()

  return (
    <Container>
      <Item
        name="AL"
        value="sq"
        selected={language}
        onClick={changeLanguage}
        aria-label="Albanian Language"
      />
      <Item
        name="EN"
        value="en"
        selected={language}
        onClick={changeLanguage}
        aria-label="English Language"
        sx={{ marginLeft: 0, borderRadius: '0.3rem 0.7rem 0.7rem 0.3rem' }}
      />
      <Selected
        sx={{
          ...(language === 'sq'
            ? {
                borderRadius: '0.7rem 0.3rem 0.3rem 0.7rem'
              }
            : {
                marginLeft: '4.3rem',
                borderRadius: '0.3rem 0.7rem 0.7rem 0.3rem'
              })
        }}
      />
    </Container>
  )
}

export default LanguageToggle
