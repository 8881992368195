/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Auth as AmplifyAuth } from 'aws-amplify'

const Auth = createContext()

function AuthProvider({ children }) {
  const [auth, setAuth] = useState({
    isAuthenticated: false,
    isLoading: true
  })

  const logout = () => {
    AmplifyAuth.signOut()
      .then((res) => {
        setAuth({
          isAuthenticated: false,
          isLoading: false
        })
      })
      .catch(() => {})
  }

  const getAuthenticatedUser = async (userData) => {
    if (!auth.isAuthenticated) {
      try {
        let data
        if (userData) {
          data = userData
        } else {
          data = await AmplifyAuth.currentAuthenticatedUser({ bypassCache: true })
        }
        setAuth({
          isAuthenticated: true,
          isLoading: false
        })
      } catch (error) {
        setAuth({
          isAuthenticated: false,
          isLoading: false
        })
      }
    }
  }

  useEffect(() => {
    getAuthenticatedUser()
  }, [])

  return (
    <Auth.Provider
      value={{
        ...auth,
        logout,
        getAuthenticatedUser
      }}
    >
      {children}
    </Auth.Provider>
  )
}
AuthProvider.propTypes = {
  children: PropTypes.any
}

function useAuth() {
  const context = useContext(Auth)
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}

export { AuthProvider, useAuth }
export default AuthProvider
