/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Icon } from '@iconify/react'
import closeFill from '@iconify/icons-eva/close-fill'
import { Box, Drawer, Button, SwipeableDrawer } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'

import { MHidden } from '.'
import Logo from './Logo'
import NavSection from './NavSection'

const DRAWER_WIDTH = '20rem'

const DrawerPlaceHolder = styled('div')({
  width: DRAWER_WIDTH,
  flexShrink: 0
})

function Sidebar({ isOpenSidebar, onOpenSidebar, onCloseSidebar, dismissible, sidebarConfig }) {
  const { pathname } = useLocation()

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar()
    }
  }, [pathname])

  const renderContent = (
    <>
      <Box sx={{ padding: '2rem' }}>
        {/* <Box component="div" to="/" sx={{ display: 'inline-flex' }}> */}
          <Logo />
        {/* </Box> */}
        <MHidden width="lgUp">
          <Button
            onClick={onCloseSidebar}
            sx={{
              position: 'absolute',
              right: '2rem',
              minWidth: 'unset',
              borderRadius: '50%',
              color: 'var(--menu_accent)',
              border: '0.0625rem solid var(--menu_accent)',
              padding: '1rem'
            }}
          >
            <Icon icon={closeFill} fontSize="1.5rem" />
          </Button>
        </MHidden>
      </Box>
      <NavSection navConfig={sidebarConfig} />
      <Box sx={{ flexGrow: 1 }} />
    </>
  )

  return (
    <>
      <MHidden width="lgUp">
        <SwipeableDrawer
          open={isOpenSidebar}
          onOpen={onOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: {
              width: '100%',
              backgroundColor: 'var(--background)',
              boxShadow: `
                0px 8px 10px -5px var(--shadow_020),
                0px 16px 24px 2px var(--shadow_014),
                0px 6px 30px 5px var(--shadow_012)
              `,
              paddingBottom: '2em'
            }
          }}
        >
          {renderContent}
        </SwipeableDrawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              backgroundColor: 'var(--background)',
              borderWidth: 0,
              boxShadow: 'unset',
              paddingRight: '1em',
              paddingBottom: '2em'
            }
          }}
        >
          {renderContent}
        </Drawer>
        <DrawerPlaceHolder />
      </MHidden>
    </>
  )
}

Sidebar.defaultProps = {
  dismissible: false
}

Sidebar.propTypes = {
  dismissible: PropTypes.bool,
  isOpenSidebar: PropTypes.bool,
  onOpenSidebar: PropTypes.func,
  onCloseSidebar: PropTypes.func,
  sidebarConfig: PropTypes.array
}

export default Sidebar
