import { Navigate, useRoutes } from 'react-router-dom'

import { PostLayout } from './layouts'
import { AdminBlog, Login, NewPost, NewCategory } from './pages'
import { PrivateRoute } from './components'

export default function Router() {
  return useRoutes([
    { path: '/admin/login', element: <Login /> },
    {
      path: '/admin',
      element: <PostLayout />,
      children: [
        { path: 'blog/:id', element: <PrivateRoute path="/admin/blog/:id" component={AdminBlog} /> }
      ]
    },
    {
      path: '/admin/new-post',
      element: <PrivateRoute path="/admin/new-post" component={NewPost} />
    },
    {
      path: '/admin/post/:id',
      element: <PrivateRoute path="/admin/post/:id" component={NewPost} />
    },
    {
      path: '/admin/group/:id/:groupId',
      element: <PrivateRoute path="/admin/group/:id/:groupId" component={AdminBlog} />
    },
    {
      path: '/admin/new-category',
      element: <PrivateRoute path="/admin/new-category" component={NewCategory} />
    },
    {
      path: '/admin/new-group',
      element: <PrivateRoute path="/admin/new-group" component={NewCategory} />
    },
    {
      path: '/admin/category/:id',
      element: <PrivateRoute path="/admin/category/:id" component={NewCategory} />
    },
    {
      path: '/admin/group/:id',
      element: <PrivateRoute path="/admin/group/:id" component={NewCategory} />
    },
    { path: '*', element: <Navigate to="/admin/blog/empty" replace /> }
  ])
}
