import gql from 'graphql-tag'

export const LIST_CATEGORIES = gql`
  query listCategories($listCategoriesInput: ListInput) {
    listCategories(listCategoriesInput: $listCategoriesInput) {
      nextToken
      categories {
        id
        nameEn
        nameSq
        orderNr
        url
      }
    }
  }
`

export const DELETE_CATEGORY = gql`
  mutation deleteCategory($id: String!) {
    deleteCategory(id: $id) {
      id
    }
  }
`

export const GET_CATEGORY = gql`
  query getCategory($fields: String, $id: String!) {
    getCategory(fields: $fields, id: $id) {
      id
      nameEn
      nameSq
      descEn
      descSq
      shortDescEn
      shortDescSq
      url
      orderNr
      imageUrl {
        key
        url
      }
    }
  }
`

export const CREATE_CATEGORY = gql`
  mutation createCategory(
    $nameEn: String!
    $nameSq: String!
    $descEn: String!
    $descSq: String!
    $shortDescEn: String!
    $shortDescSq: String!
    $orderNr: Int!
    $url: String!
    $imageUrl: String
    $key: String
  ) {
    createCategory(
      input: {
        nameEn: $nameEn
        nameSq: $nameSq
        descEn: $descEn
        descSq: $descSq
        shortDescEn: $shortDescEn
        shortDescSq: $shortDescSq
        orderNr: $orderNr
        url: $url
        imageUrl: { url: $imageUrl, key: $key }
      }
    ) {
      id
    }
  }
`

export const UPDATE_CATEGORY = gql`
  mutation putCategory(
    $id: String!
    $nameEn: String
    $nameSq: String
    $descEn: String
    $descSq: String
    $shortDescEn: String
    $shortDescSq: String
    $orderNr: Int
    $url: String!
    $imageUrl: String
    $key: String
  ) {
    putCategory(
      input: {
        id: $id
        nameEn: $nameEn
        nameSq: $nameSq
        descEn: $descEn
        descSq: $descSq
        shortDescEn: $shortDescEn
        shortDescSq: $shortDescSq
        orderNr: $orderNr
        url: $url
        imageUrl: { url: $imageUrl, key: $key }
      }
    ) {
      id
    }
  }
`
