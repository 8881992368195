import gql from 'graphql-tag'

export const CREATE_GROUP = gql`
  mutation createGroup(
    $nameEn: String!
    $nameSq: String!
    $descEn: String!
    $descSq: String!
    $shortDescEn: String!
    $shortDescSq: String!
    $orderNr: Int!
    $url: String!
    $imageUrl: String
    $key: String
  ) {
    createGroup(
      input: {
        nameEn: $nameEn
        nameSq: $nameSq
        descEn: $descEn
        descSq: $descSq
        shortDescEn: $shortDescEn
        shortDescSq: $shortDescSq
        orderNr: $orderNr
        url: $url
        imageUrl: { url: $imageUrl, key: $key }
      }
    ) {
      id
      imageUrl {
        key
        url
      }
    }
  }
`

export const UPDATE_GROUP = gql`
  mutation putGroup(
    $id: String!
    $nameEn: String
    $nameSq: String
    $descEn: String
    $descSq: String
    $shortDescEn: String
    $shortDescSq: String
    $orderNr: Int
    $url: String!
    $imageUrl: String
    $key: String
  ) {
    putGroup(
      input: {
        id: $id
        nameEn: $nameEn
        nameSq: $nameSq
        descEn: $descEn
        descSq: $descSq
        shortDescEn: $shortDescEn
        shortDescSq: $shortDescSq
        orderNr: $orderNr
        url: $url
        imageUrl: { url: $imageUrl, key: $key }
      }
    ) {
      id
      imageUrl {
        key
        url
      }
    }
  }
`

export const DELETE_GROUP = gql`
  mutation deleteGroup($id: String!) {
    deleteGroup(id: $id) {
      id
      imageUrl {
        url
        key
      }
    }
  }
`

export const LIST_GROUPS = gql`
  query listGroups($listGroupsInput: ListInput) {
    listGroups(listGroupsInput: $listGroupsInput) {
      nextToken
      groups {
        id
        nameEn
        nameSq
        createdAt
        imageUrl {
          url
          key
        }
        descEn
        descSq
      }
    }
  }
`

export const GET_GROUP = gql`
  query getGroup($fields: String, $id: String!) {
    getGroup(fields: $fields, id: $id) {
      id
      nameEn
      nameSq
      descEn
      descSq
      imageUrl {
        url
        key
      }
      shortDescEn
      shortDescSq
      url
      orderNr
    }
  }
`
