import PropTypes from 'prop-types'
import { NavLink as RouterLink, matchPath, useLocation, useNavigate } from 'react-router-dom'
import { styled } from '@material-ui/core/styles'
import { LoadingButton } from '@material-ui/lab'
import { Box, List, ListItemText, ListItemButton } from '@material-ui/core'
import { useLanguage } from '../controllers'
import { t } from '../translations'

const ListItemStyle = styled((props) => <ListItemButton disableGutters disableRipple {...props} />)(
  ({ theme }) => ({
    margin: '0.5rem 0',
    color: 'var(--menu_accent)',
    position: 'relative',
    textTransform: 'capitalize',
    marginLeft: '2rem',
    padding: '0rem',
    marginTop: '1em',
    marginRight: '2rem',
    borderRadius: '0.5rem',
    transition: '.3s',
    [theme.breakpoints.up('lg')]: {
      marginRight: '0rem'
    },
    '&:hover': {
      backgroundColor: 'var(--background)',
      '& .MuiListItemText-root': {
        borderColor: 'var(--menu_accent)'
      },
      '&:before': {
        borderColor: 'var(--menu_accent)'
      }
    },
    '&:before': {
      position: 'absolute',
      left: '1rem',
      width: '1rem',
      height: '1rem',
      margin: 'auto 0',
      content: "''",
      borderRadius: '50%',
      transition: '.3s',
      border: '0.0625rem solid var(--menu_accent_010)',
      backgroundColor: 'var(--menu_accent_010)'
    }
  })
)

function NavItem({ item, active }) {
  const { language } = useLanguage()
  const isActiveRoot = active(item.path)
  const { title, path } = item

  const activeRootStyle = {
    color: 'var(--menu_text)',
    '&:before': {
      borderColor: 'var(--menu_text)',
      backgroundColor: 'var(--menu_text_010)'
    },
    '&:hover': {
      '&:before': {
        borderColor: 'var(--menu_text)',
        backgroundColor: 'transparent',
        transition: '.1s'
      }
    }
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      replace
      sx={{
        ...(isActiveRoot && activeRootStyle)
      }}
    >
      <ListItemText
        primary={title?.[language]}
        primaryTypographyProps={{
          fontFamily: 'Raleway, sans-serif',
          fontSize: '1.5rem',
          fontWeight: 500
        }}
        className="ListItemText"
        sx={{
          padding: '0.5rem 1rem',
          paddingLeft: '3rem',
          borderRadius: '0.5rem',
          background: 'var(--card)',
          border: '0.0625rem solid var(--card)',
          transition: '.2s',
          boxShadow: `
            0px 8px 10px -5px var(--shadow_020),
            0px 16px 24px 2px var(--shadow_014),
            0px 6px 30px 5px var(--shadow_012)
          `,
          ...(isActiveRoot && {
            borderColor: 'var(--menu_accent)',
            backgroundColor: 'var(--menu_accent)'
          })
        }}
      />
    </ListItemStyle>
  )
}
NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func
}
function NavSection({ navConfig, ...other }) {
  useLanguage()
  const { pathname, search } = useLocation()
  const navigate = useNavigate()

  const match = (path) => (path ? !!matchPath({ path, end: false }, `${pathname}${search}`) : false)
  const newCategory = () => navigate('/admin/new-category')

  return (
    <Box {...other}>
      <LoadingButton
        size="large"
        variant="contained"
        sx={{ marginLeft: '2rem', marginRight: '2rem' }}
        onClick={() => newCategory()}
        // disabled={loading || Boolean(error)}
      >
        {t('post_new_category_button')}
      </LoadingButton>
      <List disablePadding>
        {navConfig.map((item) => (
          <NavItem key={item.id} item={item} active={match} />
        ))}
      </List>
    </Box>
  )
}
NavSection.propTypes = {
  navConfig: PropTypes.array
}

export default NavSection
