import { withStyles } from '@material-ui/styles'

const GlobalStyles = withStyles({
  '@global': {
    '*': {
      margin: 0,
      padding: 0,
      boxSizing: 'border-box'
    },
    html: {
      width: '100%',
      height: '100%',
      '-ms-text-size-adjust': '100%',
      '-webkit-overflow-scrolling': 'touch'
    },
    body: {
      width: '100%',
      height: '100%'
    },
    '#root': {
      width: '100%',
      height: '100%'
    },
    input: {
      '&[type=number]': {
        MozAppearance: 'textfield',
        '&::-webkit-outer-spin-button': { margin: 0, WebkitAppearance: 'none' },
        '&::-webkit-inner-spin-button': { margin: 0, WebkitAppearance: 'none' }
      }
    },
    textarea: {
      '&::-webkit-input-placeholder': { color: 'var(--grey)' },
      '&::-moz-placeholder': { opacity: 1, color: 'var(--grey)' },
      '&:-ms-input-placeholder': { color: 'var(--grey)' },
      '&::placeholder': { color: 'var(--grey)' }
    },
    a: { color: 'var(--primary)' },
    img: { display: 'block', maxWidth: '100%' }
  }
})(() => null)

export default GlobalStyles
