import { useParams } from 'react-router-dom'
import { Grid, Container } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'
import parse from 'html-react-parser'
import POSTS from '../_mocks_/related'

import Page from '../components/Page'
import { BlogPostListCard } from '../components/_dashboard/blog'

const PageContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column'
  }
}))

const CoverContainer = styled('div')({
  position: 'relative',
  top: '-8rem',
  marginBottom: '-8rem',
  marginLeft: '-2rem',
  marginRight: '-2rem'
})

const Cover = styled('img')({
  display: 'flex',
  width: '100%',
  height: '30rem',
  borderRadius: '0rem 0rem 0.3rem 0.3rem',
  objectFit: 'cover',
  overflow: 'hidden',
  marginBottom: '1rem'
})
const PublishDate = styled('span')({
  position: 'absolute',
  bottom: '1rem',
  left: '1rem',
  padding: '0.3rem 1rem',
  borderRadius: '1rem',
  color: 'var(--menu_text)',
  backgroundColor: 'var(--menu_accent)'
})

function Post() {
  const { id } = useParams()

  return (
    <Page title={id}>
      <Grid component={PageContainer} container spacing={2}>
        <Grid item xs={12} md={12}>
          <CoverContainer>
            <Cover src={`/static/mock-images/covers/cover_${Number(id) + 1}.jpg`} alt="cover" />
            <PublishDate>07-09-2021</PublishDate>
          </CoverContainer>
        </Grid>
        <Grid item xs={12} md={8} flexWrap="wrap" overflow="scroll" marginBottom="3rem">
          <h2>Let Nature Flow From Your Brush</h2>
          {parse(
            `<br /><br /><p><img style="display: block; margin-left: auto; margin-right: auto;" title="Tiny Logo" src="https://www.tiny.cloud/labs/android-chrome-256x256.png" alt="TinyMCE Logo" width="128" height="128" /></p><br /><br />
            <h2 style="text-align: center;">Welcome to the <span style="color: #fbeeb8;">TinyMCE</span> <span style="color: #f8cac6;">Cloud demo!</span></h2><br />
            <p>Please try out the features provided in this full featured example (excluding <a href="../../../apps/">Premium Plugins</a> ).</p><br /><br />
            <h2>Got questions or need hbbbbbb</h2><br />
            <ul>
            <li>Our <a class="mceNonEditable" href="../../">documentation</a> is a great resource for learning how to c</li>
            <li>Have a specific question? Try the <a href="https://stackoverflow.com/questions/tagged/tinymce" target="_blank" rel="noopener"><code>tinymce</code> tag at Stack Overflow</a>.</li>
            <li>We also offer enterprise grade support as part of <a href="../../../pricing">TinyMCE premium plans</a>.</li>
            </ul><br /><br />
            <h2>A simple table to play with</h2><br />
            <table style="border-collapse: collapse; width: 100%;" border="1">
            <thead>
            <tr>
            <th>Product</th>
            <th>Cost</th>
            <th>Really?</th>
            </tr>
            </thead>
            <tbody>
            <tr>
            <td>TinyMCE Cloud</td>
            <td>Get started for free</td>
            <td>YES!</td>
            </tr>
            <tr>
            <td>Plupload</td>
            <td>Free</td>
            <td>YES!</td>
            </tr>
            </tbody>
            </table><br /><br />
            <h2>Found a bug?</h2><br />
            <p>If you think you have found a bug please create an issue on the <a href="https://github.com/tinymce/tinymce/issues">GitHub repo</a> to report it to the developers.</p><br /><br />
            <h2>Finally ...</h2><br />
            <p>Don't forget to check out our other product <a href="http://www.plupload.com" target="_blank" rel="noopener">Plupload</a>, your ultimate upload solution featuring HTML5 upload support.</p><br /><br />
            <p>Thanks for supporting TinyMCE! We hope it helps you and your users create great content.<br />All the best from the TinyMCE team.</p>`
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <h3>Artikuj te ngjashem</h3>
          {POSTS.map((post, index) => (
            <BlogPostListCard key={post.id} post={post} index={index} />
          ))}
        </Grid>
      </Grid>
    </Page>
  )
}

export default Post
