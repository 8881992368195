import I18n from 'i18n-js'
import moment from 'moment'

import enMoment from 'moment/locale/en-gb' // English
import sqMoment from 'moment/locale/sq' // Albanian

import enLanguage from './languages/en.json' // English
import sqLanguage from './languages/sq.json' // Albanian

const translations = {
  enMoment,
  sqMoment,
  enLanguage,
  sqLanguage
}

const VALID_LANGUAGES = ['en', 'sq']
const isValid = (language) => VALID_LANGUAGES.includes(language)

I18n.locale = 'sq'

const setLanguage = (language) => {
  const locale = isValid(language) ? language : 'sq'

  localStorage.setItem('language', locale)

  I18n.translations = { [locale]: translations[`${locale}Language`] }
  I18n.locale = locale

  moment.updateLocale(locale, translations[`${locale}Moment`])
}

const configure = () => {
  const language = localStorage.getItem('language') || 'sq'

  setLanguage(language)
}

configure()

// Custom pluralization function to fallback to "other" for "one" as well, if "one" is not defined in the languages file
function pluralizationFn(count) {
  switch (count) {
    case 0:
      return ['zero', 'other']
    case 1:
      return ['one', 'other']
    default:
      return ['other']
  }
}

// Override the default pluralization function with the custom one
I18n.pluralization.default = pluralizationFn

const { t } = I18n

export { setLanguage, t }
export default I18n
