/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-this-in-sfc */
import React, { useRef, useState, useEffect, useCallback } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useLazyQuery, useMutation } from 'react-apollo'
import { useDropzone } from 'react-dropzone'

import { Icon } from '@iconify/react'
import circleX from '@iconify/icons-akar-icons/circle-x'
import { styled } from '@material-ui/core/styles'
import { Editor } from '@tinymce/tinymce-react'
import {
  TextField,
  CardContent,
  Grid,
  Fab,
  Typography,
  Button,
  Box,
  Paper,
  SwipeableDrawer
} from '@material-ui/core'
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate'
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary'
import { LoadingButton } from '@material-ui/lab'
import { Storage } from 'aws-amplify'
import { v1 as uuidv1 } from 'uuid'

import { LanguageToggle, Loader } from '../components'
import { useLanguage } from '../controllers'
import { t } from '../translations'
import { extractImagesFromHtml } from '../utils'

import { CREATE_POST, UPDATE_POST, GET_POST } from '../api/Posts.Schema'

const ContainerStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  minHeight: '100vh',
  flexDirection: 'row',
  justifyContent: 'space-between',
  backgroundColor: 'var(--white)',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column'
  }
}))

const EditorContainerStyle = styled('div')({
  display: 'flex',
  flex: 4,
  flexDirection: 'column'
})

const InputContainerStyle = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  padding: '0.8rem',
  justifyContent: 'flex-start'
})

const ImageUploadContainerStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'var(--background)',
  padding: '0.8rem 1.5rem',
  marginTop: '1rem',
  marginBottom: '1rem',
  borderRadius: '0.4rem',
  [theme.breakpoints.down(theme.breakpoints.values.md + 262)]: {
    justifyContent: 'center',
    flexDirection: 'column'
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'row'
  }
}))

const LangaugeSwitchStyle = styled('div')({
  marginTop: 10,
  marginBottom: 15,
  display: 'flex',
  alignSelf: 'center'
})

const TextContainerStyle = styled('div')(({ theme }) => ({
  marginLeft: '1rem',
  [theme.breakpoints.down(theme.breakpoints.values.md + 262)]: {
    marginLeft: 0,
    marginTop: '0.5rem'
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: '1rem'
  }
}))

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
}

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
  position: 'relative',
}

function NewPost() {
  const { state: { categoryId, groupId } } = useLocation()
  const { id } = useParams()

  const navigate = useNavigate()
  const { language } = useLanguage()
  const editorRef = useRef(null)

  const [opened, setOpened] = useState(false)
  const [coverPic, setCoverPic] = useState({})
  const [editorContent, setContent] = useState({
    sq: '',
    en: ''
  })
  const [contentPhotosSq, setContentPhotosSq] = useState([])
  const [contentPhotosEn, setContentPhotosEn] = useState([])

  const [enTitle, setEnTitle] = useState('')
  const [sqTitle, setSqTitle] = useState('')

  const [uploadLoading, setUploadLoading] = useState(false)

  const [files, setFiles] = useState([])

  const [getPost, { data: newPostData, loading: isLoading }] = useLazyQuery(GET_POST, {
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (id) {
      getPost({
        variables: {
          id
        }
      })
    }
  }, [id])

  useEffect(() => {
    const postData = newPostData?.getPost
    if (postData) {
      setEnTitle(postData?.titleEn)
      setSqTitle(postData?.titleSq)
      setContent({
        sq: postData?.contentSq,
        en: postData?.contentEn
      })
      setCoverPic({
        selectedFile: postData?.imageUrl?.url,
        key: postData?.imageUrl?.key
      })
      setContentPhotosEn(extractImagesFromHtml(postData?.contentEn))
      setContentPhotosSq(extractImagesFromHtml(postData?.contentSq))
      setFiles(postData?.galleryUrls)
    }
  }, [newPostData])

  const handleUploadClick = (event) => {
    const file = event.target.files[0]

    setCoverPic({
      name: event.target.files[0].name
    })
    setUploadLoading(true)
    Storage.put(`${categoryId}_${uuidv1()}`, file, {
      contentType: 'image/png'
    })
      .then((data) => {
        Storage.get(`${data?.key}`)
          .then((result) => {
            setCoverPic((prev) => ({
              ...prev,
              selectedFile: result,
              key: data?.key
            }))
            setUploadLoading(false)
          })
          .catch(() => {
            setUploadLoading(false)
          })
      })
      .catch(() => setUploadLoading(false))
  }

  const handleEditorChange = (content) => {
    setContent((prev) => ({
      ...prev,
      [language]: content
    }))
  }

  const [createPost, { loading: createLoading }] = useMutation(CREATE_POST)
  const [updatePost, { loading }] = useMutation(UPDATE_POST)

  const createNewPost = () => {
    const toDeleteEn = contentPhotosEn.filter(x => !extractImagesFromHtml(editorContent.en).includes(x))
    const toDeleteSq = contentPhotosSq.filter(x => !extractImagesFromHtml(editorContent.sq).includes(x))

    const toDeleteAll = toDeleteSq.concat(toDeleteEn)
    if (toDeleteAll.length > 0) {
      toDeleteAll.map((item) => Storage.remove(item.split("public/")[1])
        .then(() => { })
        .catch(() => { })
      )
    }

    const extraVars = groupId ? { groupId } : { categoryId }

    if (id) {
      updatePost({
        variables: {
          id,
          titleEn: enTitle,
          titleSq: sqTitle,
          contentEn: editorContent?.en,
          contentSq: editorContent?.sq,
          url: coverPic?.selectedFile,
          key: coverPic?.key,
          galleryUrls: files,
          ...extraVars
        }
      })
        .then((res) => navigate(-1))
        .catch(() => { })
    } else {
      createPost({
        variables: {
          titleEn: enTitle,
          titleSq: sqTitle,
          contentEn: editorContent?.en,
          contentSq: editorContent?.sq,
          url: coverPic?.selectedFile,
          key: coverPic?.key,
          galleryUrls: files,
          ...extraVars
        }
      })
        .then((res) => navigate(-1))
        .catch(() => { })
    }
  }

  const changeSqTitle = (e) => setSqTitle(e.target.value)
  const changeEnTitle = (e) => setEnTitle(e.target.value)

  const removeIcon = () => {
    Storage.remove(`${coverPic?.key}`)
      .then((res) => {
        setCoverPic({})
      })
      .catch(() => { })
  }

  const mapData = (data) => data.map((file) => Storage.put(`gallery_${categoryId}_${uuidv1()}`, file, {
    contentType: 'image/png'
  })
    .then((data) => {
      Storage.get(data?.key)
        .then((result) => setFiles(prev => prev.concat([{
          key: data?.key,
          url: result
        }])))
        .catch(() => { })
    })
    .catch(() => { })
  )

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      mapData(acceptedFiles)
    }
  })

  const handleClose = () => {
    setOpened(false)
  }

  const removeGalleryPhoto = (item) => {
    Storage.remove(`${item?.key}`)
      .then((res) => {
        setFiles(prev => {
          const index = prev.indexOf(item)
          if (index > -1) {
            prev.splice(index, 1);
          }
          return [...prev]
        })
      })
      .catch(() => { })
  }

  return (
    <>
      <ContainerStyle>
        <EditorContainerStyle>
          <Editor
            apiKey='km4olwq4n6mdaeqb3dn671lre9z2wh0xaqu3pqqgczg6pcz4'
            onInit={(evt, editor) => (editorRef.current = editor)}
            value={editorContent[language]}
            disabled={isLoading}
            onEditorChange={handleEditorChange}
            initialValue="<p> </p>"
            plugins='advlist autolink link image lists charmap print preview hr anchor pagebreak searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking table emoticons template paste'
            toolbar='undo redo | blocks | bold italic | alignleft aligncenter alignright alignjustify | 
            bullist numlist outdent indent | link image | print preview media fullpage | 
            forecolor backcolor emoticons searchreplace'
            init={{
              height: '100vh',
              menubar: true,
              resize: false,
              file_picker_callback(callback, value, meta) {
                if (meta.filetype === 'image') {
                  const input = document.createElement('input')
                  input.setAttribute('type', 'file')
                  input.setAttribute('accept', 'image/*')
                  input.onchange = () => {
                    const file = input.files[0]
                    Storage.put(`8888${categoryId}_${uuidv1()}`, file, {
                      contentType: 'image/png'
                    })
                      .then((data) => {
                        Storage.get(`${data?.key}`)
                          .then((result) => {
                            const imgSource = result?.substr(0, result.indexOf('?'))
                            if (language === 'sq') {
                              const imgsContent = contentPhotosSq
                              imgsContent.push(imgSource)
                              setContentPhotosSq(imgsContent)
                            } else {
                              const imgsContent = contentPhotosEn
                              imgsContent.push(imgSource)
                              setContentPhotosEn(imgsContent)
                            }
                            callback(imgSource, {
                              alt: file.name
                            })
                          })
                          .catch(() => { })
                      })
                      .catch(() => { })

                    const reader = new FileReader()
                    reader.readAsDataURL(file)
                  }
                  input.click()
                }
              },
              content_style: 'body { margin: 2rem 10%; }',
              placeholder: '...',
            }}
          />
        </EditorContainerStyle>

        <InputContainerStyle>
          <LangaugeSwitchStyle>
            <LanguageToggle />
          </LangaugeSwitchStyle>
          {language === 'sq' ? (
            <TextField
              fullWidth
              label={t('post_title_alb_label')}
              sx={{ marginBottom: '1rem' }}
              value={sqTitle}
              onChange={changeSqTitle}
            />
          ) : (
            <TextField
              fullWidth
              value={enTitle}
              label={t('post_title_en_label')}
              onChange={changeEnTitle}
            />
          )}
          <CardContent sx={{ padding: 0 }}>
            {coverPic?.selectedFile ? (
              <Paper
                variant="outlined"
                style={{
                  position: 'relative',
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'center',
                  marginTop: 20,
                  overflow: 'hidden'
                }}
              >
                <img src={coverPic?.selectedFile} alt="Cover pic" style={{ flexGrow: 1 }} />
                <Button
                  type="secondary"
                  onClick={removeIcon}
                  style={{
                    position: 'absolute',
                    top: 5,
                    right: 5,
                    backgroundColor: 'var(--shadow_020)'
                  }}
                >
                  <Box component={Icon} icon={circleX} sx={{ width: 20, height: 20 }} />
                </Button>
              </Paper>
            ) : (
              <Grid container justify="center" alignItems="center" sx={{ padding: 0 }}>
                <input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={handleUploadClick}
                  style={{ display: 'none' }}
                  disabled={uploadLoading}
                />
                <>{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}</>
                <label htmlFor="contained-button-file" style={{ width: '100%' }}>
                  <ImageUploadContainerStyle>
                    {uploadLoading ? (
                      <Loader style={{ height: 100 }} />
                    ) : (
                      <>
                        <Fab
                          disabled={uploadLoading}
                          component="span"
                          sx={{ color: 'var(--menu_accent)', minWidth: 56 }}
                        >
                          <AddPhotoAlternateIcon />
                        </Fab>
                        <TextContainerStyle>
                          <Typography variant="body1">{t('post_cover_pic_label')}</Typography>
                        </TextContainerStyle>
                      </>
                    )}
                  </ImageUploadContainerStyle>
                </label>
              </Grid>
            )}
          </CardContent>

          <CardContent sx={{ padding: 0, flexGrow: 1 }}>
            <Grid container justify="center" alignItems="center" sx={{ padding: 0 }}>
              <label htmlFor="button" style={{ width: '100%' }} onClick={() => setOpened(true)}>
                <ImageUploadContainerStyle>
                  <Fab
                    disabled={uploadLoading}
                    component="span"
                    sx={{ color: 'var(--menu_accent)', minWidth: 56 }}
                  >
                    <PhotoLibraryIcon />
                  </Fab>
                  <TextContainerStyle>
                    <Typography variant="body1">{
                      files?.length > 0 ? `${files?.length} photos` : t('post_import_gallery_label')
                    }</Typography>
                  </TextContainerStyle>
                </ImageUploadContainerStyle>
              </label>
            </Grid>
          </CardContent>

          <LoadingButton
            size="large"
            variant="contained"
            sx={{ marginBottom: '0.5rem' }}
            onClick={createNewPost}
            loading={createLoading || loading}
            disabled={
              enTitle === '' ||
              sqTitle === '' ||
              editorContent.en === '' ||
              editorContent.sq === '' ||
              !coverPic?.selectedFile
            }
          >
            {t('post_button_title')}
          </LoadingButton>
          <LoadingButton
            size="large"
            variant="contained"
            sx={{ marginBottom: '0.5rem' }}
            onClick={() => navigate(-1)}
            disabled={createLoading || loading}
          >
            {t('post_go_back_button')}
          </LoadingButton>
        </InputContainerStyle>
      </ContainerStyle>

      <SwipeableDrawer
        open={opened}
        onOpen={() => setOpened(true)}
        onClose={handleClose}
        anchor='bottom'
        aria-modal
        PaperProps={{
          sx: {
            position: 'absolute',
            top: 100,
            bottom: 100,
            left: 150,
            right: 150,
            backgroundColor: 'var(--background)',
            boxShadow: `
                0px 8px 10px -5px var(--shadow_020),
                0px 16px 24px 2px var(--shadow_014),
                0px 6px 30px 5px var(--shadow_012)
              `,
            padding: '2em',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }
        }}
      >
        <section style={{
          display: 'flex',
          flexDirection: 'column',
        }}>
          <div {...getRootProps({
            style: {
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: 20,
              borderWidth: 2,
              borderRadius: 2,
              borderColor: '#eeeeee',
              borderStyle: 'dashed',
              backgroundColor: ' #fafafa',
              color: '#bdbdbd',
              outline: 'none',
              transition: 'border .24s ease-in-out'
            }
          })}>
            <input {...getInputProps()} />
            <p>Drag and drop some files here, or click to select files</p>
          </div>
          <aside style={thumbsContainer}>
            {files?.map(file => (<Paper
              key={file?.key}
              variant="outlined"
              style={thumb}
            >
              <img src={file?.url} alt="gallery" style={{ flexGrow: 1 }} />
              <Button
                type="secondary"
                onClick={() => removeGalleryPhoto(file)}
                style={{
                  position: 'absolute',
                  top: -5,
                  right: -5,
                  backgroundColor: 'var(--white)',
                  minWidth: 20,
                  minHeight: 20,
                  padding: 0,
                }}
              >
                <Box component={Icon} icon={circleX} sx={{ width: 20, height: 20 }} />
              </Button>
            </Paper>
            ))}
          </aside>
        </section>
      </SwipeableDrawer>
    </>
  )
}

export default NewPost
