import React from 'react'
import { Route, Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useAuth } from '../controllers'

const PrivateRoute = ({ component: Component, ...otherProps }) => {
  const { isAuthenticated } = useAuth()

  return isAuthenticated ? (
    <Component />
  ) : (
    <Navigate to={otherProps.redirectTo ? otherProps.redirectTo : '/admin/login'} replace />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.func
}

export default PrivateRoute
