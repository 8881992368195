import { Outlet } from 'react-router-dom'
import { Icon } from '@iconify/react'
import menu2Fill from '@iconify/icons-eva/menu-2-fill'
import { Container, Stack } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'

import { IconButton, LanguageToggle, MHidden, Sidebar } from '../components'
import { useDrawer, useDrawerActions } from '../controllers'

const RootStyle = styled('div')({
  display: 'flex'
})

const OutletStyle = styled('div')({
  paddingTop: '1rem',
  paddingBottom: '6rem',
  overflowY: 'auto'
})

function Dashboard() {
  const { isOpen, sidebarConfig } = useDrawer()
  const { toggleDrawer } = useDrawerActions()
  const dismissible = false

  return (
    <RootStyle>
      <Sidebar
        isOpenSidebar={isOpen}
        onOpenSidebar={toggleDrawer}
        onCloseSidebar={toggleDrawer}
        dismissible={dismissible}
        sidebarConfig={sidebarConfig}
      />
      <Container>
        <Stack
          m="1.5rem"
          direction="row-reverse"
          alignItems="center"
          justifyContent="space-between"
        >
          <LanguageToggle />
          <MHidden width={dismissible ? 'xsDown' : 'lgUp'}>
            <IconButton onClick={toggleDrawer}>
              <Icon icon={menu2Fill} />
            </IconButton>
          </MHidden>
        </Stack>
        <OutletStyle>
          <Outlet />
        </OutletStyle>
      </Container>
    </RootStyle>
  )
}

export default Dashboard
