import React, { useState } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

import './EventItem.css'

import { t } from '../../translations'

function EventItem({ type, startTime, endTime, title, description, location, imageUrl }) {
  const [imageClasses, setImageClasses] = useState('eventItemEventImage')

  const addNewClass = (newClass) => {
    setImageClasses((current) => `${current} ${newClass}`)
  }

  const startMoment = moment(startTime)

  if (type === 'section') {
    let label = 'event_item_section_label_current'
    let month = t('event_item_section_date_current')
    if (!moment().isSame(startMoment, 'month')) {
      label = 'event_item_section_label'
      month = startMoment.format('MMMM')
    }
    return (
      <div className="eventItemRowContainer" type={type}>
        <div className="eventItemRowContent eventItemSectionContent">
          <span className="eventItemSectionLabel">{t(label)}&nbsp;</span>
          <span className="eventItemSectionMonth">{month}</span>
        </div>
      </div>
    )
  }

  return (
    <div className="eventItemRowContainer" type={type}>
      <div className="eventItemRowContent">
        <div className="eventItemEventDateTimeInfo">
          <div className="eventItemEventDay">{startMoment.format('dddd')}</div>
          <div className="eventItemEventDate">
            {t('event_item_event_date', {
              date: moment.localeData().ordinal(startMoment.date())
            })}
          </div>
          <div className="eventItemEventTime">
            {startMoment.format('hh:mm')} - {moment(endTime).format('hh:mm')}
          </div>
          <div className="eventItemEventDaysLeft">
            {t('event_item_days_left', { count: startMoment.diff(moment(), 'days') })}
          </div>
        </div>
        <div className="eventItemEventDetails">
          <div className="eventItemEventTitle">{title}</div>
          <div className="eventItemEventDescription">{description}</div>
          <div className="eventItemEventLocation">
            <div className="eventItemEventLocationIndicator" />
            <span className="eventItemEventLocationName">{location}</span>
          </div>
        </div>
        <div className="eventItemEventImageContainer">
          <img
            onLoad={() => addNewClass('eventItemEventImageLoaded')}
            className={imageClasses}
            src={imageUrl}
            alt="Random"
          />
        </div>
      </div>
    </div>
  )
}
EventItem.propTypes = {
  type: PropTypes.oneOf(['section', 'event']),
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  location: PropTypes.string,
  imageUrl: PropTypes.string
}

export default EventItem
