const amplifyConfig = {
  Auth: {
    clientId: process.env.REACT_APP_COGNITO_NEW_CLIENT_ID,
    userPoolId: process.env.REACT_APP_COGNITO_NEW_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_NEW_WEB_CLIENT_ID,
    region: process.env.REACT_APP_NEW_COGNITO_REGION,
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL
  },
  Storage: {
    AWSS3: {
      bucket: 'ata-images-blog',
      region: process.env.REACT_APP_NEW_COGNITO_REGION,
      identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL
    }
  },
  Analytics: {
    disabled: true
  }
}

export default amplifyConfig
