import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Icon } from '@iconify/react'
import { Link as RouterLink, useParams } from 'react-router-dom'
import { useMutation } from 'react-apollo'
import shareFill from '@iconify/icons-eva/share-fill'
import checkmarkFill from '@iconify/icons-eva/checkmark-fill'
import closeFill from '@iconify/icons-eva/close-fill'
import deleteBin6Line from '@iconify/icons-ri/delete-bin-6-line'
import { styled } from '@material-ui/core/styles'
import { Box, Button, Link, Card, Grid, Avatar, Typography, CardContent } from '@material-ui/core'

import { t } from '../../../translations'
import { useLanguage } from '../../../controllers'
import { copyToClipboard, extractTextFromHtml } from '../../../utils'
import { fDate } from '../../../utils/formatTime'

import SvgIconStyle from '../../SvgIconStyle'

const CardMediaStyle = styled('div')({
  position: 'relative',
  paddingTop: 'calc(100% * 3 / 4)'
})

const TitleStyle = styled(Link)({
  height: 44,
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical'
})

const AvatarStyle = styled(Avatar)(({ theme }) => ({
  zIndex: 9,
  width: 32,
  height: 32,
  position: 'absolute',
  left: theme.spacing(3),
  bottom: theme.spacing(-2)
}))

const InfoStyle = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-end',
  paddingBottom: 15
})

const CoverImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
})

const SHARE_STATES = {
  SHARE: 'share',
  SUCCESS: 'success',
  ERROR: 'error'
}

function BlogPostCard({ post, index, redirectTo, shouldReplace, deletePost, groupId }) {
  const { language } = useLanguage()
  const { id: categoryId } = useParams()
  const { id, cover, title, author, createdAt, content } = post
  const latestPostLarge = index === 0
  const latestPost = index === 1 || index === 2

  const SHARE_STATE = {
    [SHARE_STATES.SHARE]: {
      icon: shareFill,
      title: t('post_share_button'),
      disabled: false
    },
    [SHARE_STATES.SUCCESS]: {
      icon: checkmarkFill,
      title: 'Copied!',
      disabled: true
    },
    [SHARE_STATES.ERROR]: {
      icon: closeFill,
      title: 'Failed!',
      disabled: true
    }
  }

  const [copied, setCopied] = useState(SHARE_STATES.SHARE)
  const [confirm, setConfirm] = useState(false)

  const path = `https://next-grupi-ata.vercel.app/category/post?cid=${categoryId}&pid=${id}`

  const shareDetails = {
    url: path,
    title: title?.[language],
    message: extractTextFromHtml(content[language]),
  }

  useEffect(() => {
    if (copied !== SHARE_STATES.SHARE) {
      setTimeout(() => {
        setCopied(SHARE_STATES.SHARE)
      }, 2000)
    }
  }, [copied])

  const handleSharing = async (e) => {
    e.preventDefault()
    if (navigator.share) {
      try {
        await navigator
          .share(shareDetails)
          .then(() => setCopied(SHARE_STATES.SUCCESS))
      } catch (error) {
        setCopied(SHARE_STATES.ERROR)
      }
    } else {
      copyToClipboard(
        shareDetails.url,
        () => setCopied(SHARE_STATES.SUCCESS),
        () => setCopied(SHARE_STATES.ERROR)
      )
    }
  }

  const activateConfirm = (e) => {
    e.preventDefault()
    if (confirm) {
      deletePost(id)
    } else {
      setConfirm(true)
    }
  }

  const shareState = SHARE_STATE[copied]

  return (
    <Grid
      component={RouterLink}
      to={redirectTo}
      state={{ categoryId, groupId }}
      replace={shouldReplace}
      item
      xs={12}
      sm={latestPostLarge ? 12 : 6}
      md={latestPostLarge ? 6 : 3}
    >
      <Card
        sx={{
          position: 'relative',
          backgroundColor: 'var(--card)',
          boxShadow: `
            0px 8px 10px -5px var(--shadow_020),
            0px 16px 24px 2px var(--shadow_014),
            0px 6px 30px 5px var(--shadow_012)
          `
        }}
      >
        <CardMediaStyle
          sx={{
            ...((latestPostLarge || latestPost) && {
              pt: 'calc(100% * 4 / 3)',
              '&:after': {
                top: 0,
                content: "''",
                width: '100%',
                height: '100%',
                position: 'absolute',
                background:
                  'linear-gradient(to top, var(--background) 0% 20%, var(--background_000) 60% 100%)'
              }
            }),
            ...(latestPostLarge && {
              pt: {
                xs: 'calc(100% * 4 / 3)',
                sm: 'calc(100% * 3 / 4.66)'
              }
            })
          }}
        >
          <SvgIconStyle
            color="paper"
            src="/static/icons/shape-avatar.svg"
            sx={{
              width: 80,
              height: 36,
              zIndex: 9,
              bottom: -15,
              position: 'absolute',
              backgroundColor: 'var(--card)',
              ...((latestPostLarge || latestPost) && { display: 'none' })
            }}
          />
          <AvatarStyle
            alt={author.name}
            src="/icons/logo.png"
            sx={{
              ...((latestPostLarge || latestPost) && {
                zIndex: 9,
                top: 24,
                left: 24,
                width: 40,
                height: 40
              })
            }}
          />

          <CoverImgStyle alt={title?.[language]} src={cover || '/icons/placeholder.png'} />
        </CardMediaStyle>

        <CardContent
          sx={{
            pt: 4,
            backgroundColor: 'var(--card)',
            ...((latestPostLarge || latestPost) && {
              bottom: 0,
              width: '100%',
              position: 'absolute',
              backgroundColor: 'transparent',
              paddingBottom: '0 !important'
            })
          }}
        >
          <Typography
            gutterBottom
            variant="caption"
            sx={{ color: 'var(--foreground)', display: 'block' }}
          >
            {fDate(createdAt)}
          </Typography>

          <TitleStyle
            color="inherit"
            variant="subtitle2"
            underline="none"
            sx={{
              color: 'var(--foreground)',
              ...(latestPostLarge && { typography: 'h5', height: 60 }),
              ...((latestPostLarge || latestPost) &&
              {
                // color: 'var(--white)'
              })
            }}
          >
            {title?.[language]}
          </TitleStyle>

          <InfoStyle>
            <Button
              type="secondary"
              disabled={shareState.disabled}
              onClick={handleSharing}
              style={{ padding: 10 }}
            >
              <Box
                component={Icon}
                icon={shareState.icon}
                sx={{ width: 16, height: 16, mr: 0.5 }}
              />
              <Typography variant="caption">{shareState.title}</Typography>
            </Button>

            <Button
              type="secondary"
              disabled={shareState.disabled}
              onClick={activateConfirm}
              style={{ padding: 10 }}
            >
              <Box component={Icon} icon={deleteBin6Line} sx={{ width: 16, height: 16, mr: 0.5 }} />
              <Typography variant="caption">
                {confirm ? t('post_confirm_button') : t('post_delete_button')}
              </Typography>
            </Button>
          </InfoStyle>
        </CardContent>
      </Card>
    </Grid>
  )
}
BlogPostCard.defaultProps = {
  shouldReplace: true
}

BlogPostCard.propTypes = {
  post: PropTypes.object.isRequired,
  index: PropTypes.number,
  redirectTo: PropTypes.string,
  shouldReplace: PropTypes.bool,
  deletePost: PropTypes.func,
  groupId: PropTypes.string
}

export default BlogPostCard
