const extractTextFromHtml = (s, space) => {
    if (typeof document === 'undefined') return ''

    const span = document.createElement('span')

    span.innerHTML = s

    if (space) {
        const children = span.querySelectorAll('*')
        for (let i = 0; i < children.length; i += 1) {
            if (children[i].textContent) children[i].textContent += ' '
            else children[i].innerText += ' '
        }
    }

    return [span.textContent || span.innerText]
        .toString()
        .replace(/ +/g, ' ')
        .slice(0, 160)
}

const extractImagesFromHtml = (content) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, "text/html");
    return Array.from(doc.getElementsByTagName('img')).map(image => image.src);
}


export {
    extractImagesFromHtml,
    extractTextFromHtml
}
