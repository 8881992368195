/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useParams, useNavigate, useSearchParams, generatePath } from 'react-router-dom'
import { Grid, Container, Fab } from '@material-ui/core'
import { LoadingButton } from '@material-ui/lab'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useLazyQuery, useMutation } from 'react-apollo'
import ArrowBack from '@material-ui/icons/ArrowBack'

import { t } from '../translations'
import { useDrawer, useLanguage } from '../controllers'
import { LIST_POSTS, DELETE_POST } from '../api/Posts.Schema'
import { LIST_GROUPS, DELETE_GROUP } from '../api/Groups.Schema'
import { DELETE_CATEGORY } from '../api/Categories.Schema'

import Page from '../components/Page'
import { BlogPostCard } from '../components/_dashboard/blog'

const query = {
  true: {
    list: LIST_GROUPS,
    delete: DELETE_GROUP,
    data: 'listGroups',
    nestedData: 'groups'
  },
  false: {
    list: LIST_POSTS,
    delete: DELETE_POST,
    data: 'listPosts',
    nestedData: 'posts'
  }
}

function Blog() {
  useLanguage()
  const { sidebarConfig } = useDrawer()
  const navigate = useNavigate()
  const { id, groupId } = useParams()
  const [search] = useSearchParams()

  const shouldPost = search.get('blog')
  const isKujri = search.get('kujri') || false

  const isNyje = (item) => item?.url?.startsWith('http') && item?.id === id
  const nyjeUrl = sidebarConfig?.find(isNyje)?.url

  const [confirm, setConfirm] = useState(false)

  const newPost = () => {
    if (nyjeUrl) {
      window.open(nyjeUrl, '_blank')
    } else if (isKujri === 'true') {
      navigate('/admin/new-group', { state: { isGroup: true } })
    } else {
      navigate('/admin/new-post', { state: { categoryId: id, groupId } })
    }
  }
  const editCategory = () =>
    groupId
      ? navigate(`/admin/group/${groupId}`, { state: { isGroup: true } })
      : navigate(`/admin/category/${id}`)

  const [posts, setPosts] = useState([])
  const [isDeleting, setDeleting] = useState(false)

  const [getPosts, { data, loading, error, refetch }] = useLazyQuery(
    query?.[isKujri]?.list,
    {
      fetchPolicy: 'network-only'
    }
  )

  const [removePost] = useMutation(query?.[isKujri]?.delete)
  const [removeCategory] = useMutation(DELETE_CATEGORY)

  useEffect(() => {
    setPosts([])
    if (id) {
      let variables = {
        listPostsInput: {
          filter: {
            name: 'category',
            value: id
          },
          limit: 30
        }
      }

      if (isKujri === 'true') {
        variables = {
          listGroupsInput: {
            limit: 30
          }
        }
      } else if (groupId) {
        variables = {
          listPostsInput: {
            filter: {
              name: 'group',
              value: groupId
            },
            limit: 30
          }
        }
      }
      getPosts({ variables })
    }
  }, [id])

  const mapData = (unmappedData) => {
    const newData = []
    unmappedData
      ?.map((post, index) =>
        newData.push({
          id: post?.id,
          cover: post?.imageUrl?.url,
          imageKey: post?.imageUrl?.key,
          title: {
            en: post?.titleEn || post?.nameEn,
            sq: post?.titleSq || post?.nameSq
          },
          createdAt: post?.createdAt,
          author: {
            name: 'aaa',
            avatarUrl: `/static/mock-images/avatars/avatar_${index + 1}.jpg`
          },
          content: {
            en: post?.contentEn,
            sq: post?.contentSq
          }
        })
      )
      .sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt))
    return newData
  }

  useEffect(() => {
    if (data) {
      const listData = query?.[isKujri]?.data
      const nestedData = query?.[isKujri]?.nestedData
      const newData = mapData(data?.[listData]?.[nestedData])
      if (isDeleting) {
        setPosts(newData)
        setDeleting(false)
      } else {
        setPosts((prev) => prev.concat(newData))
      }
    }
  }, [data])

  const deletePost = (id) => {
    setDeleting(true)
    removePost({
      variables: {
        id
      }
    }).then(() => {
      refetch()
    })
  }

  const activateConfirm = () => {
    if (confirm) {
      removeCategory({
        variables: {
          id
        }
      }).then(() => {
        navigate('/admin/blog/empty', { replace: true })
        window.location.reload()
      })
    } else {
      setConfirm(true)
    }
  }

  const onLoadMore = () => {
    if (id) {
      let variables = {
        listPostsInput: {
          filter: {
            name: 'category',
            value: id
          },
          nextToken: data?.listPosts?.nextToken,
          limit: 30
        }
      }

      if (isKujri === 'true') {
        variables = {
          listGroupsInput: {
            limit: 30,
            nextToken: data?.listGroups?.nextToken
          }
        }
      } else if (groupId) {
        variables = {
          listPostsInput: {
            filter: {
              name: 'group',
              value: groupId
            },
            nextToken: data?.listPosts?.nextToken,
            limit: 30
          }
        }
      }
      getPosts({ variables })
    }
  }

  const shouldDisable =
    loading || Boolean(error) || (shouldPost === 'false' && !nyjeUrl) || id === 'empty'

  const buttonTitle = () => {
    let title = ''
    if (nyjeUrl) {
      title = t('post_open_nyje')
    } else if (isKujri === 'true') {
      title = t('post_new_group_button')
    } else {
      title = t('post_new_post_button')
    }
    return title
  }

  const secondaryButtonTitle = () => {
    let title = ''
    if (confirm) {
      title = t('post_confirm_button')
    } else if (groupId) {
      title = t('post_delete_group_button')
    } else {
      title = t('post_delete_category_button')
    }
    return title
  }

  return (
    <Page title={id}>
      <Container sx={{ marginY: '2rem', display: 'flex', flexDirection: 'column' }}>
        {groupId && (
          <button
            type="button"
            htmlFor="button"
            style={{
              borderWidth: 0,
              backgroundColor: 'transparent',
              marginBottom: '5rem',
              alignSelf: 'flex-start'
            }}
            onClick={() => navigate(-1)}
            onKeyDown={() => navigate(-1)}
            disabled={loading}
          >
            <Fab component="span" sx={{ color: 'var(--menu_accent)', minWidth: 56 }}>
              <ArrowBack />
            </Fab>
          </button>
        )}
        <div>
          <LoadingButton
            size="large"
            variant="contained"
            sx={{ minWidth: 200, marginRight: 5, marginBottom: 5 }}
            onClick={newPost}
            disabled={shouldDisable}
          >
            {buttonTitle()}
          </LoadingButton>
          <LoadingButton
            size="large"
            variant="contained"
            sx={{ minWidth: 200, backgroundColor: 'text.secondary', marginBottom: 5 }}
            onClick={editCategory}
            disabled={id === 'empty'}
          >
            {groupId ? t('post_edit_group_button') : t('post_edit_category_button')}
          </LoadingButton>
        </div>
        <Grid container spacing="1.5rem">
          {posts.length === 0 && !loading && id !== 'empty' && !error && (
            <LoadingButton
              size="large"
              variant="contained"
              sx={{ margin: 'auto', mt: 20, minWidth: 200, backgroundColor: 'gray' }}
              onClick={activateConfirm}
            >
              {secondaryButtonTitle()}
            </LoadingButton>
          )}
          {posts.map((post, index) => (
            <BlogPostCard
              key={post.id}
              post={post}
              index={index}
              redirectTo={
                isKujri === 'true' ? `/admin/group/${id}/${post?.id}` : `/admin/post/${post?.id}`
              }
              shouldReplace={false}
              deletePost={deletePost}
              groupId={groupId}
            />
          ))}
        </Grid>
        {data?.[query[isKujri]?.data]?.nextToken && (
          <button
            type="button"
            htmlFor="button"
            style={{
              borderWidth: 0,
              backgroundColor: 'transparent',
              alignSelf: 'center',
              marginTop: 50
            }}
            onClick={onLoadMore}
            onKeyDown={onLoadMore}
          >
            <Fab variant="extended" sx={{ color: 'var(--menu_accent)' }} size="large">
              {t('post_load_more')}
              <ExpandMoreIcon />
            </Fab>
          </button>
        )}
      </Container>
    </Page>
  )
}

export default Blog
