import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'

function Logo({ sx, ...props }) {
  return (
    <Box
      component="img"
      src="/icons/logo.png"
      sx={{ width: '8rem', height: '8rem', ...sx }}
      {...props}
    />
  )
}
Logo.propTypes = {
  sx: PropTypes.object
}

export default Logo
