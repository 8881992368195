function Backdrop() {
  return {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          background: 'var(--background_050)',
          '&.MuiBackdrop-invisible': {
            background: 'transparent'
          }
        }
      }
    }
  }
}

export default Backdrop
