import { memo } from 'react'
import PropTypes from 'prop-types'
import { CircularProgress } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'

const Container = styled('div')({
  display: 'flex',
  height: '-webkit-fill-available',
  alignItems: 'center',
  justifyContent: 'center'
})

const Content = styled(CircularProgress)({
  width: '15rem',
  height: '15rem',
  color: 'var(--menu_accent)'
})

const Loader = memo(({ style }) => (
  <Container style={style}>
    <Content id="loader" />
  </Container>
))
Loader.propTypes = {
  style: PropTypes.object,
}

export default Loader
