/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-this-in-sfc */
import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useLazyQuery, useMutation } from 'react-apollo'
import { Icon } from '@iconify/react'
import circleX from '@iconify/icons-akar-icons/circle-x'
import { styled } from '@material-ui/core/styles'
import {
  TextField,
  CardContent,
  Grid,
  Fab,
  Typography,
  Button,
  Box,
  Paper
} from '@material-ui/core'
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate'
import ArrowBack from '@material-ui/icons/ArrowBack'
import { LoadingButton } from '@material-ui/lab'
import { Storage } from 'aws-amplify'
import { v1 as uuidv1 } from 'uuid'

import { LanguageToggle, Loader } from '../components'
import { useLanguage } from '../controllers'
import { t } from '../translations'

import { CREATE_CATEGORY, UPDATE_CATEGORY, GET_CATEGORY } from '../api/Categories.Schema'
import { CREATE_GROUP, UPDATE_GROUP, GET_GROUP } from '../api/Groups.Schema'

const ContainerStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  minHeight: '100vh',
  backgroundColor: 'var(--white)'
}))

const InputContainerStyle = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  padding: '1rem',
  justifyContent: 'center'
})

const SubContainerStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'space-between',
  margin: 'auto',
  backgroundColor: 'var(--grey_008)',
  maxWidth: 1000,
  borderRadius: 12,
  paddingTop: 30,
  paddingBottom: 30,
  marginTop: 40,
  marginBottom: 40,
  [theme.breakpoints.down('lg')]: {
    maxWidth: 800
  },
  [theme.breakpoints.down(theme.breakpoints.values.sm + 262)]: {
    marginRight: 20,
    marginLeft: 20
  }
}))

const ImageUploadContainerStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'var(--white)',
  padding: '0.8rem 1.5rem',
  marginTop: '1rem',
  borderRadius: '0.4rem',
  [theme.breakpoints.down(theme.breakpoints.values.md + 262)]: {
    justifyContent: 'center',
    flexDirection: 'column'
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'row'
  }
}))

const LangaugeSwitchStyle = styled('div')({
  marginTop: 10,
  marginBottom: 15,
  display: 'flex',
  alignSelf: 'center'
})

const TextContainerStyle = styled('div')(({ theme }) => ({
  marginLeft: '1rem',
  [theme.breakpoints.down(theme.breakpoints.values.md + 262)]: {
    marginLeft: 0,
    marginTop: '0.5rem'
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: '1rem'
  }
}))

const RowContainerStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: 10
  // [theme.breakpoints.down('sm')]: {
  //   flexDirection: 'column'
  // }
}))

const query = {
  true: {
    create: CREATE_GROUP,
    update: UPDATE_GROUP,
    get: GET_GROUP,
    getData: 'getGroup'
  },
  false: {
    create: CREATE_CATEGORY,
    update: UPDATE_CATEGORY,
    get: GET_CATEGORY,
    getData: 'getCategory'
  },
}

function NewCategory() {
  const { state } = useLocation()
  const { id } = useParams()
  const [isGroup] = useState(state?.isGroup || false)
  const navigate = useNavigate()
  useLanguage()

  const [coverPic, setCoverPic] = useState({})

  const [enTitle, setEnTitle] = useState('')
  const [sqTitle, setSqTitle] = useState('')
  const [shortEnDesc, setShortEnDesc] = useState('')
  const [shortSqDesc, setShortSqDesc] = useState('')
  const [descriptionEn, setDescriptionEn] = useState('')
  const [descriptionSq, setDescriptionSq] = useState('')
  const [order, setOrder] = useState('')
  const [url, setUrl] = useState('')

  const [uploadLoading, setUploadLoading] = useState(false)

  const [getCategory, { data: newCategoryData }] = useLazyQuery(query?.[isGroup]?.get, {
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (id) {
      getCategory({
        variables: {
          id
        }
      })
    }
  }, [id])

  useEffect(() => {
    const getData = query?.[isGroup]?.getData
    const categoryData = newCategoryData?.[getData]
    if (categoryData) {
      setEnTitle(categoryData?.nameEn)
      setSqTitle(categoryData?.nameSq)
      setShortEnDesc(categoryData?.shortDescEn)
      setShortSqDesc(categoryData?.shortDescSq)
      setDescriptionEn(categoryData?.descEn)
      setDescriptionSq(categoryData?.descSq)
      setOrder(categoryData?.orderNr)
      setCoverPic({
        selectedFile: categoryData?.imageUrl?.url,
        key: categoryData?.imageUrl?.key
      })
      setUrl(categoryData?.url)
    }
  }, [newCategoryData])

  const handleUploadClick = (event) => {
    const file = event.target.files[0]
    setCoverPic({
      name: event.target.files[0].name
    })
    setUploadLoading(true)
    Storage.put(`categ_${uuidv1()}`, file, {
      contentType: 'image/png'
    })
      .then((data) => {
        Storage.get(`${data?.key}`)
          .then((result) => {
            setCoverPic((prev) => ({
              ...prev,
              selectedFile: result,
              key: data?.key
            }))
            setUploadLoading(false)
          })
          .catch(() => {
            setUploadLoading(false)
          })
      })
      .catch(() => setUploadLoading(false))
  }

  const [createCategory, { loading: createLoading }] = useMutation(query?.[isGroup]?.create)
  const [updateCategory, { loading }] = useMutation(query?.[isGroup]?.update)

  const createNewCategory = () => {
    if (id) {
      updateCategory({
        variables: {
          id,
          nameEn: enTitle,
          nameSq: sqTitle,
          shortDescEn: shortEnDesc,
          shortDescSq: shortSqDesc,
          descEn: descriptionEn,
          descSq: descriptionSq,
          orderNr: order,
          url,
          imageUrl: coverPic?.selectedFile,
          key: coverPic?.key
        }
      })
        .then(() => navigate(-1))
        .catch(() => { })
    } else {
      createCategory({
        variables: {
          nameEn: enTitle,
          nameSq: sqTitle,
          shortDescEn: shortEnDesc,
          shortDescSq: shortSqDesc,
          descEn: descriptionEn,
          descSq: descriptionSq,
          orderNr: order,
          url,
          imageUrl: coverPic?.selectedFile,
          key: coverPic?.key
        }
      })
        .then(() => {
          navigate(-1)
        })
        .catch(() => { })
    }
  }

  const changeSqTitle = (e) => setSqTitle(e.target.value)
  const changeEnTitle = (e) => setEnTitle(e.target.value)

  const changeSqShortDesc = (e) => setShortSqDesc(e.target.value)
  const changeEnShortDesc = (e) => setShortEnDesc(e.target.value)

  const changeSqDesc = (e) => setDescriptionSq(e.target.value)
  const changeEnDesc = (e) => setDescriptionEn(e.target.value)

  const changeOrderNr = (e) => setOrder(e.target.value)
  const changeUrl = (e) => setUrl(e.target.value)

  const removeIcon = () => {
    Storage.remove(`${coverPic?.key}`)
      .then((res) => {
        setCoverPic({})
      })
      .catch(() => { })
  }

  return (
    <ContainerStyle>
      <SubContainerStyle>
        <button
          type="button"
          htmlFor="button"
          style={{
            borderWidth: 0,
            backgroundColor: 'transparent',
            alignSelf: 'flex-start',
            marginLeft: 20
          }}
          onClick={() => navigate(-1)}
          onKeyDown={() => navigate(-1)}
          disabled={createLoading || loading}
        >
          <Fab component="span" sx={{ color: 'var(--menu_accent)', minWidth: 56 }}>
            <ArrowBack />
          </Fab>
        </button>
        <InputContainerStyle>
          <LangaugeSwitchStyle>
            <LanguageToggle />
          </LangaugeSwitchStyle>
          <RowContainerStyle>
            <TextField
              fullWidth
              label={t('category_name_sq_label')}
              sx={{ marginRight: '1rem' }}
              value={sqTitle}
              onChange={changeSqTitle}
            />
            <TextField
              fullWidth
              value={enTitle}
              label={t('category_name_en_label')}
              onChange={changeEnTitle}
            />
          </RowContainerStyle>
          <RowContainerStyle>
            <TextField
              fullWidth
              label={t('category_short_desc_sq_label')}
              sx={{ marginRight: '1rem' }}
              value={shortSqDesc}
              onChange={changeSqShortDesc}
            />
            <TextField
              fullWidth
              value={shortEnDesc}
              label={t('category_short_desc_en_label')}
              onChange={changeEnShortDesc}
            />
          </RowContainerStyle>
          <RowContainerStyle>
            <TextField
              fullWidth
              label={t('category_long_desc_sq_label')}
              sx={{ marginRight: '1rem' }}
              value={descriptionSq}
              onChange={changeSqDesc}
              multiline
            />
            <TextField
              fullWidth
              value={descriptionEn}
              label={t('category_long_desc_en_label')}
              onChange={changeEnDesc}
              multiline
            />
          </RowContainerStyle>
          <RowContainerStyle>
            <TextField
              fullWidth
              label={t('category_order_number_label')}
              sx={{ marginRight: '1rem' }}
              type="number"
              value={order}
              onChange={changeOrderNr}
            />
            <TextField
              fullWidth
              value={url}
              label={t('category_url_label')}
              onChange={changeUrl}
            />
          </RowContainerStyle>
          <CardContent sx={{ padding: 0, alignSelf: 'center' }}>
            {coverPic?.selectedFile ? (
              <Paper
                variant="outlined"
                style={{
                  position: 'relative',
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'center',
                  marginTop: 20,
                  paddingTop: 5,
                  paddingBottom: 5
                }}
              >
                <img src={coverPic?.selectedFile} alt="Cover pic" style={{ height: 150 }} />
                <Button
                  type="secondary"
                  onClick={removeIcon}
                  style={{
                    position: 'absolute',
                    // top: 5,
                    right: 5,
                    backgroundColor: 'var(--shadow_020)'
                  }}
                >
                  <Box component={Icon} icon={circleX} sx={{ width: 20, height: 20 }} />
                </Button>
              </Paper>
            ) : (
              <Grid container justify="center" alignItems="center" sx={{ padding: 0 }}>
                <input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={handleUploadClick}
                  style={{ display: 'none' }}
                  disabled={uploadLoading}
                />
                <>{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}</>
                <label htmlFor="contained-button-file" style={{ width: '100%' }}>
                  <ImageUploadContainerStyle>
                    {uploadLoading ? (
                      <Loader style={{ height: 100 }} />
                    ) : (
                      <>
                        <Fab
                          disabled={uploadLoading}
                          component="span"
                          sx={{ color: 'var(--menu_accent)', minWidth: 56 }}
                        >
                          <AddPhotoAlternateIcon />
                        </Fab>
                        <TextContainerStyle>
                          <Typography color="var(--grey)" variant="body1">
                            {t('post_cover_pic_label')}
                          </Typography>
                        </TextContainerStyle>
                      </>
                    )}
                  </ImageUploadContainerStyle>
                </label>
              </Grid>
            )}
          </CardContent>
        </InputContainerStyle>
        <LoadingButton
          size="large"
          variant="contained"
          sx={{ marginTop: '0.5rem', marginLeft: '4rem', marginRight: '4rem' }}
          onClick={createNewCategory}
          loading={createLoading || loading}
          disabled={
            enTitle === ''
            || sqTitle === ''
            || !coverPic?.selectedFile
            || shortEnDesc === ''
            || shortSqDesc === ''
            || descriptionEn === ''
            || descriptionSq === ''
            || order === ''
          }
        >
          {id ? t('category_update_button_title') : t('category_create_button_title')}
        </LoadingButton>
      </SubContainerStyle>
    </ContainerStyle>
  )
}

export default NewCategory
