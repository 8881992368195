function Tooltip() {
  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'var(--foreground)'
        },
        arrow: {
          color: 'var(--foreground)'
        }
      }
    }
  }
}

export default Tooltip
